#backoffice-root .backoffice-administration-search {
    width: 300px;
    margin: 10px 0px;
}

#backoffice-root .backoffice-administration-mail-second-icon {
    position: absolute;
    bottom: 2px;
    right: 0px;
    background-color: white;
    font-size: 14px;
}

#backoffice-root .backoffice-administration-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#backoffice-root .backoffice-administration-monthpicker {
    margin-right: 10px;
    width: 100px;
}

#backoffice-root .backoffice-customer-info {
    display: flex;
    flex-flow: row wrap;
    margin-left: 35px;
    margin-right: 0px;
}

#backoffice-root .backoffice-customer-info-line {
    width: auto;
    text-align: left;
    margin-bottom: 0px;
    padding: 3px 10px;
}

#backoffice-root .backoffice-customer-info > br {
    width: 100%;
    content: '';
}

#backoffice-root .ant-layout-header {
    padding: 0 20px;
}
/* .ant-btn {
    border-radius: 30px;
} */