#backoffice-root .container-header-logo {
    height: 65%;
}

#backoffice-root .container-content {
    padding: 20px;
    height: 100%;
}

#backoffice-root .container-footer-divider {
    position: relative;
    margin: 20px;
}

#backoffice-root .container-footer-line {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
}

#backoffice-root .container-footer-dot {
    color: var(--backoffice-secondary-color);
    font-size: 16px;
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    width: 50px;
    display: flex;
    justify-content: center;
}

#backoffice-root .container-footer-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

#backoffice-root .container-footer-logo {
    height: 80px;
}

#backoffice-root .container-footer-content {
    color: #cecece;
    display: flex;
    justify-content: center;
    font-weight: 300;
    font-size: 11px;
    margin-bottom: 16px;
}