#frontoffice-root .App {
  text-align: center;
}

#frontoffice-root .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  #frontoffice-root .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#frontoffice-root .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#frontoffice-root .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#backoffice-root code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

#backoffice-root .container-alert {
  position: fixed;
  bottom: 20px;
  animation: alert-enter-leave 6s forwards;
  max-width: 50%;
  z-index: 10000;
}

@keyframes alert-enter-leave {
  0% { left: -1000px }
  10% { left: 20px }
  90% { left: 20px }
  100% { left: -1000px; }
}

#backoffice-root .flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#backoffice-root .flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#backoffice-root .flex-space-evently {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}