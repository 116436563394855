/* .ant-select-selector {
    border: none !important;
} */

#frontoffice-root .ant-layout-header {
    background-color: white;
    height: 100px;
    box-shadow: 0 2px 11px rgba(0,0,0,.09);
    z-index: 1
}
  
#frontoffice-root .ant-layout-footer {
    padding: 30px 10px 0 10px;
    background-color: white;
    color: snow;
    box-shadow: 0 2px 11px rgba(0,0,0,.09);
    z-index: 1
}
  
#frontoffice-root .ant-layout {
    background-color: #f4f4f4;
    margin-left: auto;
    margin-right: auto;
}

/* h1.ant-typography, .ant-typography h1 {
    text-align: center;
    font-size: 28px;
} */

/* h2.ant-typography, .ant-typography h2 {
    text-align: left;
    font-size: 22px;
} */

#frontoffice-root .ant-layout-content {
    position: relative;
    width: 100%;
    margin: auto;
    padding: 60px 20px;
    background-color: #f4f4f4;
}

#frontoffice-root .ant-radio, .ant-radio-wrapper {
    white-space: normal;
    text-align: left;
}

#frontoffice-root .ant-input-number-input {
    height: 100%!important;
}

#frontoffice-root .ant-popover-buttons button {
    border: 1px solid #997D61;
    border-radius: 0px;
    background-color: #997D61;
}

#frontoffice-root .ant-popover-buttons button:first-child {
    border: 1px solid #997D61;
    background-color: white;
}

#frontoffice-root .ant-popover-buttons button span{
    color: #f5f5f5;
    font-family: "Amaranth";
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.5px;

}

#frontoffice-root .ant-popover-buttons button:first-child span {
    color: #997D61;
}

#frontoffice-root .ant-popover-buttons button:hover {
    background-color: #704f3a!important;
    border-color:#704f3a!important;
    opacity: 1 !important;
}
#frontoffice-root .ant-alert-error {
    background-color: rgba(235, 181, 181, 0.5)!important;
    /* background-color: rgba(255, 242, 240, 0.5); */
    border-color: #e7a4a4;
    padding: 20px;
}

#frontoffice-root .ant-alert-success {
    background-color: rgba(246, 255, 237, 0.5)!important;
    padding: 20px;
}

#frontoffice-root .ant-popover-buttons button:first-child:hover {
    background-color: whitesmoke!important;
    border-color:#704f3a!important;
    opacity: 1 !important;
}

/* xs */
@media (max-width: 575px) {
    .ant-radio, .ant-radio-wrapper {
        text-align: justify;
    }
}

/* sm */
@media (min-width: 576px) and (max-width: 767px) {
    .ant-radio, .ant-radio-wrapper {
        text-align: justify;
    }
}

/* md */
@media (min-width: 768px) and (max-width: 991px) {
    .ant-radio, .ant-radio-wrapper {
        text-align: justify;
    }
}

/* lg */
@media (min-width: 992px)and (max-width: 1199px) { 
    
}

/* xl */
@media (min-width: 1200px) { 
    
}




#backoffice-root .ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: white;
    }
#backoffice-root .ant-layout-footer{
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: white;
}