/* Registration */
body {
    background-color: #f4f4f4;
}

* {
    color: #505050;
    font-family: "Raleway";
}

#frontoffice-root .ant-tabs-tab.ant-tabs-tab-disabled div span,
#frontoffice-root .ant-tabs-tab.ant-tabs-tab-disabled div span span svg path {
    color: rgba(0, 0, 0, 0.35) !important;
}

#frontoffice-root h1,
#frontoffice-root h2,
#frontoffice-root h3,
#frontoffice-root h4,
#frontoffice-root h5 {
    font-family: "Playfair Display";
    color: #555555 !important;
}

#frontoffice-root h1.ant-typography,
#frontoffice-root .ant-typography h1 {
    font-size: 32px;
    line-height: 1.2;
    font-weight: 400;
}

#frontoffice-root h2.ant-typography,
#frontoffice-root .ant-typography h2 {
    margin-top: 0px !important;
}

#frontoffice-root h2.ant-typography,
#frontoffice-root .ant-typography h2 {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 3px;
    font-weight: 400;
}

#frontoffice-root h3.ant-typography,
#frontoffice-root .ant-typography h3 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;
}

#frontoffice-root form {
    margin: auto !important;
    max-width: 500px;
    font-family: Arial, Helvetica, sans-serif !important;
}

#frontoffice-root .form-title {
    display: block !important;
    margin-top: 50px !important;
    font-size: 20px !important;
    line-height: 1.4 !important;
    text-transform: none !important;
    color: #2d2e33 !important;
    letter-spacing: 0 !important;
}

#frontoffice-root .form-input {
    text-align: left;
    color: #888888 !important;
    background-color: #ffffff !important;
    border-color: #cccccc !important;
    border-radius: 3px !important;
    font-size: 15px !important;
    padding: 10px 10px !important;
    border: 0 none !important;
    border-bottom: 1px solid #e5e5e7 !important;
    transition: .2s ease-in-out !important;
    transition-property: color, background-color, border-color, box-shadow !important;
}

#frontoffice-root .form-button span,
#frontoffice-root .form-button a {
    color: whitesmoke;
    font-family: "Amaranth";
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.5px;
}


#frontoffice-root .form-button {
    width: 250px;
    height: 38px;
    border-radius: 0px;
}

#frontoffice-root .form-button-parent {
    text-align: center;
}

#frontoffice-root .form-button:hover {
    background-color: #555 !important;
    border-color: #555 !important;
    opacity: 1 !important;
}

#frontoffice-root .form-button-pay {
    background-color: #997D61;
    border: 1px solid #997D61;
}

#frontoffice-root .form-button-pay:hover {
    background-color: #704f3a !important;
    border-color: #704f3a !important;
    opacity: 1 !important;
}

#frontoffice-root .form-button:disabled span {
    color: #92a0b0;
}

#frontoffice-root .form-button:disabled:hover {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
}

#frontoffice-root .form-input-select {
    padding: 5.5px 4px !important;
}

#frontoffice-root .form-input:focus {
    border-color: #093851 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* Payment Modal */

#frontoffice-root .registration-modal-payment-image {
    height: 50px;
}

/* Payment Modal Choice */

#frontoffice-root .registration-modal-choice-parent {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 20px;

}


#frontoffice-root .__ski-club-logo-img {
    margin: auto;
    margin-top: 5px;
    max-height: 100px;
}

#frontoffice-root .__sponsor-logo-img {
    margin: 0 10px;
    max-width: 120px;
}

#frontoffice-root .__back-to-top-img {
    margin-bottom: 0;
    padding-bottom: 0;
    max-width: 400px;
}

#frontoffice-root .__back-to-top-arrow path {
    color: white;
}

#frontoffice-root .__back-to-top-arrow {
    position: absolute;
    top: 15px;
    cursor: pointer;
    left: calc(50% - 5px);
}

#frontoffice-root .__registration_carousel {
    color: #fff;
    text-align: center;
    background: #37474f;
    padding-bottom: 50px;
}

#frontoffice-root .form-input {
    width: 100%;
}

#frontoffice-root .__participants-header-list {
    margin-top: 20px !important;
    font-size: 20px;
    padding: 5px;
    background-color: #37474f;
    color: whitesmoke;
    text-align: center;
}

#frontoffice-root .__participants-content-list {
    padding: 5px !important;
    border-bottom: 1px solid #37474f;
    text-align: center;

}

#frontoffice-root .__add-participant-form {
    margin-top: 10px;
}

#frontoffice-root .__cart-table {
    padding: 10px;
    background-color: white;
    border: 1px solid #37474f;
}

#frontoffice-root .__content-custom {
    max-width: 1400px;
    margin: auto;
}

#frontoffice-root .__link-round-icon {
    margin: 0 5px;
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    border-radius: 500px;
    background: #cfd9e4;
    color: #555;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
    transition-property: background-color;
}

#frontoffice-root .__link-round-icon:hover {
    background-color: #555;
}

#frontoffice-root .__link-round-icon svg path {
    transition: .1s ease-in-out;
    transition-property: color;
}

#frontoffice-root .__link-round-icon:hover svg path {
    color: #fff;

}

#frontoffice-root .__background-custom {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../img/back_80.jpg');
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: 100% auto;
    background-position: center bottom;
    filter: blur(3px) contrast(120%);
}

#frontoffice-root .__avs-input-error {
    background-color: #fff2f0 !important;
}

#frontoffice-root .__hr-line {
    width: calc(100% - 10px);
    background-color: rgba(255, 255, 255, 0.7);
    height: 1px;
    margin-bottom: 10px;
}

#frontoffice-root .__paysucces-notsettled-mailto {
    font-size: 110%;
    font-weight: bold;
}

/* xs */
@media (max-width: 575px) {
    #frontoffice-root .form-button {
        width: 100%;
        margin-top: 5px
    }

    #frontoffice-root .__back-to-top-img {
        width: 320px;
    }

    #frontoffice-root .__back-to-top-arrow {
        top: 5px;
    }
}

/* sm */
@media (max-width: 767px) {

    #frontoffice-root h1.ant-typography,
    #frontoffice-root .ant-typography h1 {
        font-size: 26px;
    }

}

/* md */
@media (min-width: 768px) and (max-width: 991px) {}

/* lg */
@media (min-width: 992px)and (max-width: 1199px) {}

/* xl */
@media (min-width: 1200px) {}


/* Mettre en gras une partie de la description seulement sur la page registration... alala les clients */
#frontoffice-root .not-bold strong {
    font-weight: normal;
}